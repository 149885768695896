import { lazy, Suspense } from "react"
import { createBrowserRouter, Outlet } from "react-router-dom"
import Spinner from "app/components/Spinner/Spinner"
import NotFound from "app/components/NotFound/NotFound"

const LandingPage = lazy(() => import("features/LandingPage"))
const FeaturePage = lazy(
  () =>
    import("features/LandingPage/OurFeatures/feature-details/FeatureDetails"),
)
const TermsAndConditionsPage = lazy(
  () => import("features/Policies/TermsAndConditions/TermsAndConditions"),
)
const PrivacyPolicyPage = lazy(
  () => import("features/Policies/Privacy/PrivacyPolicy"),
)
const ChildAbusePage = lazy(
  () => import("features/Policies/ChildAbuse/ChildAbuse"),
)
const DisclaimerPage = lazy(
  () => import("features/Policies/Disclaimer/Disclaimer"),
)
const EULAPage = lazy(() => import("features/Policies/EULA/EULA"))
const Insight = lazy(() => import("features/Insight"))
const Login = lazy(() => import("features/Auth/Login"))
const ForgotPassword = lazy(() => import("features/Auth/ForgotPassword"))
const ResetPassword = lazy(() => import("features/Auth/ResetPassword"))
const Users = lazy(() => import("features/Users"))
const BuddyEvents = lazy(() => import("features/BuddyEvents/BuddyEvents"))
const BuddyEventsVerification = lazy(
  () => import("features/BuddyEventsVerification/BuddyEventsVerification"),
)
const Subscriptions = lazy(() => import("features/Subscriptions"))
const SubscriptionFeatures = lazy(() => import("features/SubscriptionFeatures"))
const GiftsManagement = lazy(() => import("features/GiftsManagement"))
const Layout = lazy(() => import("app/layout"))
const Staffs = lazy(() => import("features/Staffs"))

const PaymentStaging = lazy(() => import("features/PaymentStaging"))
const Transactions = lazy(() => import("features/Transactions"))
const ProfileBoost = lazy(() => import("features/ProfileBoost"))
const Settings = lazy(() => import("features/Settings"))
const Auth = lazy(() => import("features/Auth/Auth"))
const ProtectedRoute = lazy(() => import("./ProtectedRoute"))

// const LandingPage = () => {
//   return <div>LANDING PAGE</div>
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <LandingPage />
      </Suspense>
    ),
  },
  {
    path: "/features/:featureId",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <FeaturePage />
      </Suspense>
    ),
  },
  {
    path: "policy",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <Outlet />
      </Suspense>
    ),
    errorElement: <NotFound title="Something occurred!" />,
    children: [
      {
        path: "terms-and-conditions",
        element: <TermsAndConditionsPage />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "child-abuse",
        element: <ChildAbusePage />,
      },
      {
        path: "disclaimer",
        element: <DisclaimerPage />,
      },
      {
        path: "eula",
        element: <EULAPage />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <Layout />
      </Suspense>
    ),
    errorElement: <NotFound title="Something occurred!" />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "insight",
            element: <Insight />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "staffs",
            element: <Staffs />,
          },
          {
            path: "buddy-events",
            element: <BuddyEvents />,
          },
          {
            path: "buddy-events-verification",
            element: <BuddyEventsVerification />,
          },
          {
            path: "user-subscriptions",
            element: <Subscriptions />,
          },
          {
            path: "subscription-features",
            element: <SubscriptionFeatures />,
          },
          {
            path: "gifts-management",
            element: <GiftsManagement />,
          },
          {
            path: "transactions",
            element: <Transactions />,
          },
          {
            path: "payment-staging",
            element: <PaymentStaging />,
          },
          {
            path: "profile-boost",
            element: <ProfileBoost />,
          },
          {
            path: "settings",
            element: <Settings />,
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <Auth />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "*",
    element: <NotFound title="Page not found" />,
  },
])

export default router
